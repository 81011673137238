.footer {
  border-top: 4px solid #000;
  padding-top: 48px;
  padding-bottom: 196px;
  @media (max-width: 900px) {
    padding-bottom: 40px;
  }
  &__title {
    font-weight: 500;
    font-size: 40px;
    @media (max-width: 900px) {
      font-size: 30px;
    }
  }

  &__footer {
    margin-top: 64px;
    margin-bottom: 140px;
    @media (max-width: 900px) {
      margin-bottom: 40px;
      margin-top: 40px;
    }
  }

  &__footer,
  &__copy {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 900px) {
      flex-direction: column;
    }
  }

  &__copy {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    opacity: 0.8;
    @media (max-width: 900px) {
      line-height: 34px;
    }
    &__right {
      letter-spacing: 0.04em;
    }
  }
}

.holder {
  background: #fff;
  color: #000;
  padding-top: 196px;
  margin-left: -40px;
  margin-right: -40px;
  padding-left: 40px;
  padding-right: 40px;
  @media(max-width: 1300px) {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;

    padding-top: 100px;
  }
  @media (max-width: 900px) {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 60px;
  }
}

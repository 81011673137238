.button {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    border: 2px solid var(--background-color);
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    svg {
        margin-left: 10px;
        transition: all 0.3s ease-in-out;
        position: relative;
        top: -1px;
        path {
            transition: all 0.3s ease-in-out;
            fill: var(--background-color);
        }
    }

    border: 2px solid var(--background-color);
    box-sizing: border-box;
    border-radius: 16px;
    padding-top: 19px;
    padding-left: 30px;
    padding-bottom: 17px;
    padding-right: 31px;
    transition: all 0.3s ease-in-out;
    &:hover {
        background-color: var(--background-color);
        color: var(--font-color);

        svg,
        img {
            transform: rotate(45deg);
            path {
                fill: var(--font-color);
            }
        }
    }
}

.black {
    background: var(--font-color);
    color: #fff;
    border-color: var(--font-color);

    &:hover {
        background: #ea4c89;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
        color: #fff !important;
        border-color: #ea4c89;
        svg path {
            fill: #fff;
        }
    }
}

.email {
    background: #000;
    color: #fff;
    border-color: #000;

    svg path {
        fill: #fff;
    }

    &:hover {
        background: #000;
        color: #fff;
        box-shadow: 0px 0px 0px 4px rgba(123, 97, 255, 0.56), 0px 4px 4px rgba(0, 0, 0, 0.25);
        svg {
            transform: none !important;
        }
        svg path {
            fill: #fff !important;
        }
    }
}

.title {
    font-weight: 500;
    font-size: 76px;
    line-height: 88px;
    margin: 0;
    max-width: 1220px;
    width: 100%;
    margin-top: 188px;
    height: 352px;
    @media (max-width: 1600px) {
        font-size: 56px;
        line-height: 68px;
        height: 282px;
        margin-top: 100px;
    }
    @media(max-width: 1300px) {
        font-size: 44px;
    }
    @media (max-width: 900px) {
        height: auto;
        font-size: 35px;
        line-height: 1.5;
        margin-top: 40px;
    }
    > span {
        display: block;
        overflow: hidden;
        @media (max-width: 900px) {
            display: inline;
        }
        &:nth-child(1) span {
            transition-delay: 0s;
        }
        &:nth-child(2) span {
            transition-delay: 0.15s;
        }
        &:nth-child(3) span {
            transition-delay: 0.25s;
        }
        &:nth-child(4) span {
            transition-delay: 0.35s;
        }
        > span {
            display: block;
            transform: translateY(100%);
            transition: all 0.3s ease;
            @media (max-width: 900px) {
                display: inline;
            }
        }
    }

    &__active {
        > span > span {
            transform: none;
        }
    }

    &__ended {
        span {
            transition-delay: 0s !important;
        }
    }
}

.date {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: var(--font-color);
    opacity: 0.8;
    margin-top: 32px;
    @media (max-width: 900px) {
        font-size: 14px;
        margin-top: 16px;
    }
}

.social {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 124px;
    @media (max-width: 1600px) {
        margin-top: 100px;
    }
    @media (max-width: 900px) {
        margin-top: 40px;
    }
    img {
        filter: var(--image-filter);
    }
    li {
        margin-left: 24px;
        border-radius: 8px;
        transition: all 0.3s ease-in-out;
        &:hover {
            background: var(--social-hover);
        }
        a {
            img,
            svg {
                display: block;
            }
        }
    }
}

.no_margin {
    margin-top: 4px !important;
    @media (max-width: 900px) {
        margin-top: 24px !important;
    }

    li:hover {
        background: rgba(0, 0, 0, 0.04);
    }

    li {
        @media (max-width: 900px) {
            margin: 0 8px;
        }
    }

    img {
        filter: none !important;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 4px solid var(--font-color);
    padding-top: 48px;
    margin-bottom: 80px;
    @media(max-width: 900px) {
        padding-top: 20px;
        margin-bottom: 40px;
    }
    &__title {
        font-weight: 500;
        font-size: 76px;
        line-height: 88px;
        color: var(--font-color);
        @media(max-width: 1300px) {
            font-size: 44px;
            line-height: 1.6;
        }
        @media(max-width: 900px) {
            font-size: 40px;
        }
    }

    &__link {
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.04em;
        color: var(--font-color);
        opacity: 0.8;
    }
}

.skills {
  margin-top: 115px;
  background: var(--background-color);
  padding-bottom: 177px;
  position: relative;
  z-index: 2;
  margin-left: -40px;
  margin-right: -40px;
  padding-left: 40px;
  padding-right: 40px;
  @media(max-width: 1300px) {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  @media(max-width: 900px) {
    margin-top: 0px;
    padding-bottom: 60px;
  }

  border-radius: 64px;
  @media(max-width: 900px) {
    padding-left: 20px;
    padding-right: 20px;
    margin-left: -20px;
    margin-right: -20px;
    border-radius: 0;
  }
  &__skill {
    font-weight: 500;
    font-size: 48px;
    line-height: 49px;
    color: var(--font-color);
    border-bottom: 2px solid var(--line-color);
    padding-bottom: 48px;
    padding-top: 49px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media(max-width: 900px) {
      font-size: 24px;
      padding: 24px 0;
      line-height: 1.6;
      flex-direction: column;
      text-align: left;
      justify-content: flex-start;
      align-items: flex-start;
    }

    &__name {
      display: flex;
      align-items: center;
    }

    &:hover img {
      opacity: 1;
    }

    img {
      margin-left: 24px;
      opacity: 0;
      transition: all .25s ease-in-out;
    }

    &__lang {
      color: rgba(255, 255, 255, 0.48);
      margin-right: 10px;
    }
    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      opacity: 0.8;
      letter-spacing: 0.04em;
    }
  }
}

.link {
  cursor: pointer;
}
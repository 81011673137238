@import url("https://fonts.googleapis.com/css2?family=Epilogue:wght@400;500;600&display=swap");
@import "aos/dist/aos.css";

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Epilogue", sans-serif;

  /* max-width: 1920px; */
  /* margin: 0 auto; */
  /* width: 100%; */
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 calc(8.3333% + 0px);
  max-width: 1920px;
}

html {
}

.app {
  --background-color: #fff;
  --font-color: #000;
  --menu-color: rgba(0, 0, 0, 0.04);
  --menu-hover: rgba(0, 0, 0, 0.04);
  --image-filter: none;
  --line-color: rgba(0, 0, 0, 0.16);
  --social-hover: rgba(0,0,0,.04);
  background: var(--background-color);
  color: var(--font-color);
  padding: 0 40px;
  transition: all 0.26s ease-in-out;
}

@media(max-width: 1300px) {
  .app {
    padding: 0 20px;
  }
  .container {
    padding: 0;
  }
}

@media(max-width: 900px) {
  .app {
    padding: 0 20px;
  }
  .container {
    padding: 0;
  }
}

.app.inverted {
  --menu-hover: rgba(255, 255, 255, 0.08);
  --image-filter: invert(98%) sepia(6%) saturate(490%) hue-rotate(239deg) brightness(116%) contrast(100%);
  --menu-color: rgba(255, 255, 255, 0.1);
  --line-color: rgba(255, 255, 255, 0.16);
  --social-hover: rgba(255, 255, 255, 0.16);
  --background-color: #000;
  --font-color: #fff;
}

.tooltip {
  position: relative;
}

.tooltip__body {
  position: absolute;
  background: #7b61ff;
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
  color: white;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.04em;
  font-variant: small-caps;
  text-transform: uppercase;
  top: 100%;
  margin-top: 16px;
  left: 50%;
  transform: translateX(-50%);
  width: 205px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  background: #7B61FF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding-top: 2px;
  z-index: 2;

}
.tooltip__body svg {
  position: absolute;
  top: -9px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}
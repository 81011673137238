.portfolioChanger {
    background: var(--menu-color);
    border-radius: 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    width: 436px;
    height: 64px;
    align-items: center;
    padding: 4px;
    position: relative;
    @media(max-width: 900px) {
        height: 60px;
    }
    a:nth-child(1).active ~ .portfolioChanger__bar {
        left: 4px;
    }
    a:nth-child(2).active ~ .portfolioChanger__bar {
        left: calc(50% + 4px);
    }
    &__bar {
        position: absolute;
        top: 0;
        left: 0;
        color: var(--background-color) !important;
        background: var(--font-color);
        box-shadow: 0px 0px 2px 4px rgba(0, 0, 0, 0.08);
        border-radius: 16px;
        top: 4px;
        left: 4px;
        width: calc(50% - 8px);
        height: calc(100% - 8px);
        transition: all .25s ease-in-out;
    }

    a {
        position: relative;
        z-index: 2;
        padding: 21px 35px 19px 35px;
        @media(max-width: 900px) {
            padding-left: 15px;
            padding-right: 15px;
            font-size: 14px;
        }
        border-radius: 16px;
        display: block;
        width: 50%;
        text-align: center;
        transition: all 0.25s ease-in-out;
        color: var(--font-color);
        &:not(.active):hover {
            background: var(--menu-hover);
        }
        &.active {
        }
    }
}

.active {
    color: var(--background-color) !important;
}

.button {
  text-align: center;
  margin-top: 119px;
  @media(max-width: 900px) {
    margin-top: 60px;
  }
}

.portfolio {
  margin-bottom: 155px;
  @media(max-width: 900px) {
    margin-bottom: 60px;
  }
}
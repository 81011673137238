.form {
  background: #000;
  padding: 30px;
  color: #fff;;
  margin-top: -77px;
  padding-top: 281px;
  padding-bottom: 224px;
  margin-left: -40px;
  margin-right: -40px;
  @media(max-width: 1300px) {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 180px;
    padding-bottom: 150px;
  }
  @media(max-width: 900px) {
    margin-left: -20px;
    margin-right: -20px;
    padding-top: 180px;
    padding-bottom: 60px;
  }

  &__title {
    text-align: center;
    font-weight: 500;
    font-size: 76px;
    line-height: 88px;
    @media(max-width: 1300px) {
        font-size: 44px;
        line-height: 1.6;
    }
    @media(max-width: 900px) {
      font-size: 35px;
      line-height: 1.4;
    }
    strong {
      font-weight: 500;
      opacity: 0.48;
      display: block;
    }
  }

  &__box {
    max-width: 720px;
    width: 100%;
    margin: 0 auto;
    border-radius: 32px;
    background: #fff;
    margin-top: 120px;
    height: 720px;
    position: relative;
    overflow: hidden;
    @media(max-width: 900px) {
      margin-top: 40px;
      height: 400px;
      max-height: 400px;
      border-radius: 24px;
    }
  }
}

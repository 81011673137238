.item {
  max-width: 1216px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 80px;
  @media (max-width: 1535px) {
    max-width: unset;
    max-width: calc(100% - 8.3333% - 8.3333%);
  }
  @media(max-width: 1300px) {
    max-width: 100%;
  }
  @media (max-width: 900px) {
    margin-bottom: 40px;
  }
  &__image {
    border-radius: 32px;
    display: block;
    max-width: 100%;
    height: auto;
    transition: all 0.25s ease-in-out;
  }
  &__title {
    font-weight: 500;
    font-size: 40px;
    line-height: 41px;
    color: var(--font-color);
    margin-top: 32px;
    margin-top: 29px;
    @media (max-width: 900px) {
      font-size: 24px;
      margin-top: 20px;
    }
    img {
      opacity: 0;
      margin-left: 24px;
      transition: all 0.25s ease-in-out;
    }
  }
}

.item:hover {
  .item__title img {
    opacity: 1;
  }
  .item__image {
    box-shadow: 0px 32px 48px -2px rgba(126, 126, 126, 0.08);
  }
}

.placeholder {
  &__image {
    background-color: rgba(#000, 0.04);
    border-radius: 32px;
    padding-top: 75%;
  }
}

.about {
    background: var(--font-color);
    color: var(--background-color);
    border-radius: 64px;
    margin-top: 152px;
    padding-top: 156px;
    padding-bottom: 156px;
    margin-bottom: 156px;
    @media(max-width: 1300px) {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    @media(max-width: 900px) {
        margin-top: 40px;
        padding-top: 40px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 40px;
        margin-bottom: 40px;
        margin-left: -20px;
        margin-right: -20px;
        border-radius: 0;
    }

    &__button {
        margin-top: 79px;
        @media(max-width: 900px) {
            margin-top: 40px;
        }
    }

    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media(max-width: 1300px) {
            padding: 0 60px;
            flex-direction: column-reverse;
        }
        @media(max-width: 900px) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    h2 {
        font-weight: 500;
        font-size: 76px;
        line-height: 88px;
        margin: 0;
        margin-bottom: 81px;
        @media(max-width: 1300px) {
            font-size: 44px;
            line-height: 1.6;
        }
        @media(max-width: 900px) {
            font-size: 40px;
            line-height: 1.6;
            margin-bottom: 40px;
        }
    }

    p {
        font-weight: 400;
        font-size: 24px;
        line-height: 40px;
        margin: 0;
        margin-bottom: 40px;
        @media(max-width: 900px) {
            font-size: 20px;
        }
    }

    &__text {
        width: 47.9%;
        @media(max-width: 1300px) {
            width: 100%;
        }
        @media(max-width: 900px) {
            width: 100%;
            margin-top: 40px;
        }
    }

    &__image {
        background: linear-gradient(180deg, #dbcefb 0%, #c3b0f0 100%);
        border: 4px solid rgba(0, 0, 0, 0.08);
        box-sizing: border-box;
        display: flex;
        height: 800px;
        align-items: center;
        justify-content: center;
        border-radius: 64px;
        width: 38.75%;
        @media(max-width: 1300px) {
            width: 100%;
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
            height: 500px;
            margin-bottom: 40px;
        }
        @media(max-width: 900px) {
            border-radius: 24px;
        }
        @media(max-width: 900px) {
            height: auto;
            width: 100%;
        }

        img {
            display: block;
            max-width: 100%;
            height: auto;
        }
    }
}

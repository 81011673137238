.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 32px;
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
    // padding-left: 40px;
    // padding-right: 40px;
    @media (max-width: 900px) {
        padding-top: 20px;
    }

    &__contact {
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        animation: header-contact 1s infinite alternate;
        cursor: pointer;
        @media (max-width: 900px) {
            display: none;
        }
    }

    &__logo img {
        display: block;
        filter: var(--image-filter);
        transition: all 0.3s ease;

        @media (max-width: 900px) {
            display: none;
        }
    }
}

@-webkit-keyframes header-contact {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes header-contact {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
